import { Button, useToast } from '@chakra-ui/react';
import { requestVideoCall } from '../../../../../services/WatchService';
import { errorToast } from '../../../../../utils/toast';

const CreateMeetingComponent = ({ smartWatch }) => {
	const toast = useToast();

	const submitCreateMeeting = () => {
		console.log('Entra aqui');

		const { imei, watchId } = smartWatch;

		localStorage.setItem('meetUsername', 'app');
		localStorage.setItem('meetRoomId', imei + '_AoW');
		localStorage.setItem('meetNickName', imei);
		localStorage.setItem('meetTag', '123456');
		localStorage.setItem('meetPassword', '123456');
		localStorage.setItem('meetWatchId', watchId);

		window.open('/meeting');

		// requestVideoCall(imei)
		// 	.then(res => {
		//		window.open('/meeting');
		// 		console.log('Response', res);
		// 	})
		// 	.catch(e => {
		// 		console.log('Error', e);
		// 		return errorToast(toast, 'No se ha podido conectar.');
		// 	});
	};

	return (
		<Button
			bg={'teal300'}
			color={'white'}
			rounded={'md'}
			_hover={{
				transform: 'translateY(-2px)',
				boxShadow: 'lg',
			}}
			onClick={submitCreateMeeting}
		>
			Realizar Videollamada
		</Button>
	);
};
export default CreateMeetingComponent;
