import {
	AbsoluteCenter,
	HStack,
	Heading,
	Image,
	Spacer,
	Spinner,
	Stack,
	Tag,
	TagLabel,
	Text,
	VStack,
	Box,
} from '@chakra-ui/react';
import { LocationPatientProfile } from './LocationPatientProfile';
import { convertMinSeconds } from '../../../../../utils/ConvertMinSeconds';
import { ProgressBarSteps } from './ProgressBarSteps';
import CreateMeetingComponent from './CreateMeetingComponent';

export const DataSmartwatchPatientProfile = ({
	dataPatient,
	refreschData,
	refreshDataPatient,
	refreshCounter,
	patient,
}) => {
	const time = convertMinSeconds(refreshCounter);

	const isConnected = dataPatient.connectionStatus === true;

	return (
		<Stack w={'100%'} direction={'column'} gap={5}>
			<Stack
				w={'100%'}
				justify={'space-between'}
				alignItems={'center'}
				gap={5}
				direction={'row'}
			>
				<Stack direction={'row'} alignItems={'center'} gap={5}>
					<Text fontWeight={'bold'} fontSize={'sm'}>
						Estado del dispositivo
					</Text>
					<Tag
						size={'sm'}
						p={2}
						borderRadius='full'
						variant='solid'
						bgColor={isConnected ? 'green' : 'red'}
					>
						<TagLabel>
							{isConnected ? 'Activo' : 'Inactivo'}
						</TagLabel>
					</Tag>
				</Stack>
				<CreateMeetingComponent smartWatch={patient?.SmartWatch} />
				<Tag
					size={'sm'}
					p={2}
					borderRadius='full'
					variant='solid'
					colorScheme='teal'
					cursor={'pointer'}
					onClick={() => refreschData()}
				>
					<TagLabel>Refrescar</TagLabel>
				</Tag>
			</Stack>
			{Object.keys(dataPatient).length === 0 ? (
				<Stack>
					<AbsoluteCenter>
						<Text>No tiene asignado smartwatch</Text>
					</AbsoluteCenter>
				</Stack>
			) : !refreshDataPatient ? (
				<Stack
					direction={'column'}
					gap={{ base: 1, sm: 3, xl: 1 }}
					overflowY={'auto'}
					maxH={{ base: '68vh', lg: 'none' }}
				>
					<Stack
						direction={{ base: 'column', sm: 'row' }}
						gap={{ base: 1, sm: 5, xl: 1 }}
					>
						<Stack
							w={'100%'}
							bgColor={'gray.200'}
							borderRadius={10}
							p={{ base: 2, lg: 4 }}
							textAlign={'center'}
							justify={'center'}
						>
							<VStack gap={0}>
								<Text>BPM</Text>
								<Text
									fontSize={{ base: '3xl', lg: '4xl' }}
									fontWeight={'bold'}
								>
									{dataPatient?.vitals?.heartRate ||
										'Sin datos'}
								</Text>
							</VStack>
							<HStack gap={1} justify={'center'}>
								<Image
									src='/bpmOk.png'
									w={{ base: '50px', lg: null }}
								/>
							</HStack>
						</Stack>
						<Stack
							w={'100%'}
							bgColor={'gray.200'}
							borderRadius={10}
							p={{ base: 2, lg: 5 }}
							textAlign={'center'}
							justify={'center'}
						>
							<Text>Presión sanguínea</Text>
							<Text
								fontSize={{ base: '3xl', lg: '4xl' }}
								fontWeight={'bold'}
							>
								{dataPatient?.vitals?.bloodPressure
									?.bloodPressure1 || 'NA'}
								/
								{dataPatient?.vitals?.bloodPressure
									?.bloodPressure2 || 'NA'}
							</Text>
						</Stack>
						<Stack
							w={'100%'}
							bgColor={'gray.200'}
							borderRadius={10}
							p={{ base: 2, lg: 5 }}
							textAlign={'center'}
							justify={'center'}
						>
							<Text>Oxígeno en sangre</Text>
							<Text
								fontSize={{ base: '3xl', lg: '4xl' }}
								fontWeight={'bold'}
							>
								{dataPatient?.vitals?.oxygen || 'Sin datos'}
							</Text>
						</Stack>
					</Stack>
					<Spacer />
					<Stack direction={{ base: 'column', lg: 'row' }}>
						<Stack
							w={{ base: '100%', lg: '50%' }}
							h={{ base: '6rem', sm: '10rem', xl: '10rem' }}
							bgColor={'gray.200'}
							borderRadius={10}
							justify={'center'}
							alignItems={'center'}
							textAlign={'center'}
						>
							<VStack gap={0}>
								<Text>Temperatura</Text>
								<Text
									fontSize={{ base: '3xl', lg: '4xl' }}
									fontWeight={'bold'}
								>
									{dataPatient?.temperatures?.temperature
										? `${dataPatient.temperatures.temperature} °C`
										: 'Sin datos'}
								</Text>
							</VStack>
						</Stack>
						<Stack
							w={{ base: '100%', lg: '50%' }}
							h={{ base: '10rem', sm: '10rem', xl: '10rem' }}
							bgColor={'gray.200'}
							borderRadius={10}
							justify={'center'}
							gap={10}
						>
							<ProgressBarSteps dataPatient={dataPatient} />
						</Stack>
					</Stack>
					<Box h={350}>
						<Stack
							direction={{ base: 'column', lg: 'row' }}
							w={'100%'}
							h={{ base: '25rem', xl: '20rem' }}
							spacing={2}
						>
							<Stack
								w={{ base: '100%', lg: '50%' }}
								h={'100%'}
								bgColor={'white'}
								borderRadius={10}
								mt={'2'}
								overflow={'hidden'}
								position={'relative'}
							>
								<LocationPatientProfile
									latitude={patient?.refLocation?.lat}
									longitude={patient?.refLocation?.lon}
									accuracy={patient?.refLocation?.accuracy}
								/>
								<AbsoluteCenter
									top={6}
									bgColor={'rgba(0, 0, 0, 0.5)'}
									color={'white'}
									p={2}
									borderRadius={8}
								>
									<Text fontSize={'md'} fontWeight={'bold'}>
										Geovalla: radio de{' '}
										{patient?.refLocation?.accuracy ||
											'N/A'}{' '}
										metros
									</Text>
								</AbsoluteCenter>
							</Stack>
							<Stack
								w={{ base: '100%', lg: '50%' }}
								h={'100%'}
								bgColor={'white'}
								borderRadius={10}
								mt={'2'}
								overflow={'hidden'}
							>
								<LocationPatientProfile
									latitude={dataPatient?.lastLocation?.lat}
									longitude={dataPatient?.lastLocation?.lon}
									accuracy={
										dataPatient?.lastLocation?.accuracy
									}
								/>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			) : (
				<VStack h={'70vh'} alignItems={'center'} justify={'center'}>
					<Heading zIndex={5}>{time}</Heading>
					<Text>Recogiendo datos del paciente</Text>
					<Spinner />
				</VStack>
			)}
		</Stack>
	);
};
